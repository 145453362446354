.align-evenely{
    display: flex;
    justify-content: space-between;
}
.test-radio input{
    size: 30px;
    width: 30px;
    height: 30px;
    background-color: gray;
    cursor: pointer;
}
.answer{
    border: 1px solid darkgray;
    border-radius: 5px;
    font-size: large;
    color: white;
    background-color: darkgray;
  }
  
.correctAnswer{
    background-color: #198754;
  }
  
  .wrongAnswer{
    background-color: red;
  }