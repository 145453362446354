.circle-container {
    position: fixed;
    bottom: 60px;
    right: 5px;
    width: 60px;
    height: 60px;
    padding: 2px;
}

.main-circle {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #0b4d78;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.main-circle {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.small-circle {
    position: absolute;
    width: 70px;
    height: 70px;
    background-color: #0E3659;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0;
    transition: all 0.5s ease;
    pointer-events: none;
    transform: scale(0);
    font-size: 10px !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.small-circle span {
    text-align: center;
    font-size: 0.8em;
}

.top-circle {
    top: -100px;
    left: 50%;
    transform: translateX(-50%) translateY(15px);
    /* background-image: url(../assets/images/psychology1.png); */
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    -webkit-transform: translateX(-50%) translateY(15px);
    -moz-transform: translateX(-50%) translateY(15px);
    -ms-transform: translateX(-50%) translateY(15px);
    -o-transform: translateX(-50%) translateY(15px);
}

.bottom-circle {
    bottom: 200px;
    left: 50%;
    transform: translateX(-50%) translateY(50px);
    /* background-image: url(../assets/images/math-class1.png); */
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    -webkit-transform: translateX(-50%) translateY(50px);
    -moz-transform: translateX(-50%) translateY(50px);
    -ms-transform: translateX(-50%) translateY(50px);
    -o-transform: translateX(-50%) translateY(50px);
}

.show {
    opacity: 1;
    pointer-events: all;
    /* transform: translateX(-50%)  scale(1);
    -webkit-transform: translateX(-50%)  scale(1);
    -moz-transform: translateX(-50%)  scale(1);
    -ms-transform: translateX(-50%)  scale(1);
    -o-transform: translateX(-50%)  scale(1); */
}






.aiConvTwo {
    right: 10px;
    bottom: 200px;
    z-index: 100;
}

@keyframes zoomInOut {
    0%, 100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }
}

.chat-box {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 10px;
    background-color: #f1f1f1;
    padding: 10px;
}

.input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputTitle {
    width: 100% !important;
    border-radius: 7px !important;
    -webkit-border-radius: 7px !important;
    -moz-border-radius: 7px !important;
    -ms-border-radius: 7px !important;
    -o-border-radius: 7px !important;
}


.aiButton {
    background-color: #4169E2 !important;
    color: white !important;

}