.user-mobile-nave{
    display: block;
    position: fixed;
    top: 90vh;
    left: 0px;
    right:0px;
    background-color: #fff;
    box-shadow: 0 0 4px 3px gray;
    width: 100vw !important;
    height: 70px;
    overflow-x: auto;
    overflow-y: hidden;
    z-index: 10000;
}

.user-mobile-nave::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    visibility: hidden;

  }
  
  /* Track */
  .user-mobile-nave ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    visibility: hidden;
  }
   
  /* Handle */
  .user-mobile-nave ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  /* ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  } */
.user-mobile-nave nav{
    height: 100%;
}

.user-mobile-nave ul{
 display: flex;
 list-style: none;
 padding: 0px ;
 margin: 0px;
 height: 100%;
}
.user-mobile-tap{
    display: inline-block;
    text-decoration: none;
    color: #283476;
    font-size: 15px;
    text-align: center;
    width: 25vw;
    height: 100%;
    box-shadow: 1px 1px 3px 0px gray;
}