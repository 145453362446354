.media-item {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-bottom: 20px;
    height: 400px;
    /* Fixed card height */
    display: flex;
    flex-direction: column;
}

.media-image-container {
    width: 100%;
    height: 500px;
    /* Fixed height for image container */
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 10px;
}

.media-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* This ensures images maintain aspect ratio */
}

.media-details {
    flex: 1;
    padding: 10px 0;
}