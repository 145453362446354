.input-comp-cont{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    height: fit-content;
}
.input-comp{
    width:200px;
    height: 30px;
    border-radius:20px;
}
