.headerTitle {
    color: white;
    width: 100%;
    background: #2C6DCE;
}

.namePrograam{
    color:#001C45 !important;
    text-align: right !important;
}

.phone{
    border: 1px solid #001C45;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.file-name{
    color:black;
}

.custom-tab{
    background-color: transparent !important;
}

.custom-tabs .nav-link {
    border: none !important;
    border-radius: 0 !important;
    color: #007bff;
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    background-color: transparent;
}

.custom-tabs .nav-link.active {
    border: none !important;
    background-color: #f8f9fa !important;
    border-bottom: 2px solid #007bff !important;
    color: #007bff !important;
}

.custom-tabs .nav-link:hover {
    border: none !important;
    background-color: #f8f9fa;
    color: #007bff;
}
