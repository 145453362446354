.cls-drop-menu p , .submenu-cont a{
    text-align: center;
    /* height: 40px !important; */
    padding: 0;
}
.cls-drop-menu-item{
    height: 40px;
}
.cls-drop-menu > .dropdown-menu{
    /* z-index: 100000 !important; */
    /* height: 300px; */
    /* overflow-y: scroll; */
}
.superVtable td, .superVtable th{
    font-size: 15px;
}
.superVtable{
    width: 70vw;
}
.cls-drop-menu-item-submenu{
}
.submenu-cont{

}
.add-super{
    cursor: pointer;
    background-color:chocolate;
    color:#fff;
    border-radius: 5px;
    width: max-content;
    padding: 0 5px 0 5px;
}
.add-super:hover{
  background-color: rgba(210, 105, 30, 0.753);
}
.contact-btn{
    cursor: pointer;

}
.contact-btn-icon{
    color:rgb(88, 88, 231)
}


.dropdown-menu {
    max-height: none !important;
    overflow: visible !important;
  }
  
  .table-responsive {
    overflow: visible !important;
  }
  