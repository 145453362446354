.lang-cont-com{
    display: flex;
   align-items: center;
   /* width: 100px; */
}
.lang-switch{
    background-color: transparent;
    border: 0px;
    color: #FFFFFF;
    width:27px;
    display: inline;
    text-align: start;

}
.lang-switch option{
    background-color: gray;
}
#lang-name{
    color: #111;
    display: inline-block;
    width: 20px;
    text-transform: capitalize;
}
/*  */