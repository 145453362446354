.nextLecBoxHeader {
  cursor: pointer;
}

.stdStudyProg {
  padding: 30px 0;
}

.stdStudyProg .boxEmptyStat {
  border-radius: 8px;
  border: 1px solid #dfa73a;
  padding: 30px 0;
}

.stdStudyProg .boxEmptyStat .emptyTitle {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #5c110b;
}

.stdStudyProg .boxEmptyStat .emptyContent {
  width: fit-content;
  padding: 10px 50px;
  border-radius: 8px;
  background: #8c1c13;
  color: white;
  margin: auto;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.stdStudyProg .oneBoxStd {
  position: relative;
  padding: 0px, 42px, 0px, 0px;
  border-radius: 10px;
  border: 0.54px;
  border: 0.54px solid #dfa73a;
  box-shadow: 4px 5px 2.4000000953674316px 0px #5c110bab;
  padding: 20px 30px;
  gap: 20px;
}

.stdStudyProg .oneBoxStd .imageVect {
  position: absolute;
  top: 0;
  right: 50px;
  z-index: 1000;
  width: 37.01px;
  height: 54.84px;
}

.stdStudyProg .parentImg {
  width: 108px;
  height: 108px;
  border: 1px solid #dfa73a;
  border-radius: 50%;
  margin: 0 auto;
}

.stdStudyProg .techName {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #5c110b;
  margin-top: 10px;
}

.stdStudyProg .teachContact {
  width: 195px;
  height: 62px;
  border-radius: 8px;
  background-color: #dfa73a;
  color: white;

  font-family: Poppins;
  font-size: 20px;
  font-weight: 800;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
}

.stdStudyProg .stu_rightSide {
  /* margin-right: 75px; */
}

.stdStudyProg .stu_rightSide .stu_rightSideLin {
  width: 188px;
  height: 7px;
  border-radius: 7px;
  background-color: #5c110b;
}



.stdStudyProg .stu_rightSide .pro {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: right;
}

.stdStudyProg .stu_rightSide .class {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: right;
}

.stdStudyProg .stu_rightSide .hour {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
}

/* nextLec */

.stdStudyProg .nextLecBox {

  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #dfa73a;
  padding: 35px;
}

.stdStudyProg .nextLecTitle {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  color: #5c110b;
}

.stdStudyProg .nextLecBox .nextLecBoxContent {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
}

.addclass_btn {
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  background: #2B6CCD;
  margin-top: 10px;
  color: white;
}

.meeting_btn {
  width: 278px;
  height: 62px;
  border-radius: 8px;
  border: 1px solid rgba(92, 17, 11, 1);
  margin-top: 10px;
  gap: 0px;
  /* background: rgba(92, 17, 11, 1); */
  background-color: white;
}

.font-meeting_btn {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 800;
  line-height: 36px;
  text-align: center;
  color: rgba(92, 17, 11, 1);

}

.contact-super {
  background-color: transparent;
  border-radius: 5px;
}

.contact-super:hover {
  background-color: #ffffff56;
}

.font-addclass_btn {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 800;
  line-height: 50px;
  text-align: center;

}

.st-drop-menu button {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 800;
  line-height: 36px !important;
  text-align: center;
  background-color: #5c110b !important;
  width: 278px;
  height: 62px;
  border-radius: 8px;
  margin: 10px 0 10px 0;

}

.st-drop-menu p {
  text-align: center;
  height: 40px !important;
  padding: 0;
  margin: 10px 0 10px 0;
}

.st-drop-menu-item {
  height: 40px;
}


.handleBox {
  width: 50% !important;
}


@media (max-width: 1000px) {
  .stdStudyProg .oneBoxStd .imageVect {
    position: absolute;
    top: 0;
    right: 100px;
  }

  .stdStudyProg .parentImg {
    width: 80px;
    height: 80px;
  }

  .stdStudyProg .techName {
    font-size: 15px;
  }

  .stdStudyProg .teachContact {
    width: 140px;
    height: 40px;
    line-height: 40px;
  }

  .stdStudyProg .stu_rightSide {
    margin-right: 5px;
    margin-top: 40px;
  }

  .stdStudyProg .stu_rightSide .stu_rightSideLin {
    width: 80px;
    height: 7px;
  }

  .stdStudyProg .stu_rightSide .pro {
    font-size: 20px;
  }

  .stdStudyProg .stu_rightSide .class {
    font-size: 18px;
  }

  .stdStudyProg .stu_rightSide .hour {
    font-size: 14px;
  }

  .addclass_btn,
  .meeting_btn {
    width: 156px;
    height: 45px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 800;
    line-height: 21px;
    text-align: center;

  }

  .st-drop-menu button {
    width: 156px;
    height: 45px;
  }
}

@media(max-width:768px) {
  .handleBox {
    width: 100% !important;
  }
}