#pdf-content{
    /* height: 70vh; */
    /* overflow-y: scroll; */
}
#pdf-body-cont{
        /* height: 70vh; 
    overflow-y: scroll; */
}
#pdf-row{
   border: 1px solid gray !important;
}

#pdf-title{
    text-align: center;
}
    
