.joinUsTeacher{
    width:80%;
    margin: auto;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    background: linear-gradient(180deg, #2B6CCD 0%, #3779DA 100%);
    text-align:center;
    margin-bottom: 120px;
    color:white;
    padding:40px;
}