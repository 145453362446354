.filter-cont{
  /* border:1px solid; */
  width:100%;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 20px 0 20px 0;
}
.filter-title{
  font-size: 17px;
  color: #0F2A51;
}
.drop-down-filter{
    width: 100% !important;
}
