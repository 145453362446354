/* container */


.stcontainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* padding: 15px; */
    margin: 10px 0px 10px 0px;
    height: fit-content;
    color: black;
}

.col {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navButton {
    text-decoration: none;
    /* border: 1px solid #fff; */
    padding: 0.5em;
    border-radius: 5px;

    text-align: center;
    /* height: fit-content; */
}

.navButton:hover, .btn:hover {
    background-color: #120C54 !important;
    color: #fff !important;
}

.btn {
    padding: 0.5em;
    background-color: #293476;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    border: 0px;
}