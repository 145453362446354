@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --background-color: radial-gradient(25.31% 24.98% at 0% 15.35%, #CCDFFF 0%, #ECF3FF %) !important;
  --text-color: #000000 !important;
}



[data-theme='dark'] {
  --background-color: #120C54 !important;
  --text-color: white !important;
}

body {
  background: var(--background-color) !important;
  color: var(--text-color) !important;
  margin: 0;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

input::placeholder {
  direction: inherit;
  text-align: inherit;
}
.font {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "slnt"0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.color {
  color: #2B6CCD;
}

.sharedColor {
  color: #0F2A51 !important;
}

.puplicBlack {
  color: black;
}

.puplicWhite {
  color: white;
}

.background {
  background-color: #2B6CCD !important;
}

.sharedBackground {
  background-color: #0F2A51;
  color: white;
  text-align: center;
  font-size: 25px;
  padding: 20px;
  width: 90%;
  border-radius: 10px;
  margin: 50px auto;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.backShared {
  background-color: #0F2A51;
}

.link {
  text-decoration: none;
}

.buttonsGroub {
  background-color: #2B6CCD !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  color: white !important;
  margin-left: 15px;
}

.special {
  border: 1px solid #2B6CCD !important;
  color: #2B6CCD !important;
  margin-left: 15px;
}

.spacePage {
  margin-top: 100px;
}

.scrolled.light {
  background-color: white !important;
}

.scrolled.dark {
  background-color: #120C54 !important;
}

.btnfooter {
  background-color: #120C54 !important;
  color: white !important;
}





.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: #2B6CCD !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-next:before, .slick-prev:before {
  content: '→';
  background: #2B6CCD;
  padding: 5px;
  border-radius: 30px;
}



/* Footer */
.footer {
  text-align: right !important;
  background-color: #2C6DCE !important;
}

.footer.light {
  background-color: #2C6DCE !important;
  color: white;
}

.footer.dark {
  background-color: #120C54 !important;
  color: white;
}


ul {
  list-style-type: none;
}

.sharedStatic {
  margin-top: 120px;
}

.sharedStatic p {
  border-top: 0.5px solid #2C6DCE;
  padding: 10px;
}

.sharedStatic p:last-child {
  border-top: 0.5px solid #2C6DCE;
  border-bottom: 0.5px solid #2C6DCE;
}

.active-link {
  color: #2C6DCE !important;
  /* يمكنك تغيير اللون أو أي style آخر تريده */
  font-weight: bold !important;
  position: relative;
}

.link-text {
  position: relative;
}

.active-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  margin-left: 5px;
  vertical-align: middle;
}



.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #0E3F89;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: #2B6CCD;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.formStyle {
  width: 50%;
}

/* Modals */
.namePrograam {
  color: #2B6CCD !important;
  font-weight: 500;
}

.modalBook {
  color: white;
}


.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #2C6DCE;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

.buttonOpacity:hover {
  background-color: #0F2A51 !important;
  transition: 0.1s ease;
  -webkit-transition: 0.1s ease;
  -moz-transition: 0.1s ease;
  -ms-transition: 0.1s ease;
  -o-transition: 0.1s ease;
}


.aboutUs .info {
  color: white;
  text-align: right;
}


.navbar-toggler {
  color: #0F2A51 !important;
}

.textHeader {
  text-align: right;
}

.handleCamera {
  background: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  cursor: pointer;
}

#home {
  overflow-x: hidden !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__input-container {
  width: 100% !important;
}

.react-datepicker__input-container input {
  width: 100% !important;
}


/* shared responsive */
@media(max-width:768px) {
  .aboutUs {
    flex-direction: column-reverse;
  }

  .aboutUs .info {
    text-align: center;
  }

  .join {
    width: 80%;
    margin: auto;
    text-align: center;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .textHeader {
    text-align: center;
  }

  .formStyle {
    width: 100%;
  }

  .imgJoin {
    width: 100%;
    margin-bottom: 20px;
  }

  .teacherImage {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .footerDiv {
    flex-direction: column-reverse;
    text-align: center;
  }

  .programsData {
    flex-direction: column-reverse;
  }

  .handleJoin {
    text-align: center !important;
  }

  .access {
    display: none;
  }
}


.dropdown-toggle{
  width:100% !important;
}