/* leftSide arrow left  Icone   */




/******** part of buttons سجل الدخول  أنشئ حسابك الشخصي  ***/


.imagePart {
  /* object-fit: fill; */
  text-align: end;

}

/* part of login form  */
.loginpage .formPart {
  /* border: 2px solid #dfa73a; */
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 0px, 22px, 0px, 22px;
  border-radius: 10px;
  padding: 0 20px;
  height: 100%;
  background-color: white;
  text-align: right;
}

/* input style  */
.loginpage .formPart form input {
  padding: 15px 16px 15px 16px;
  gap: 10px;
  border-radius: 8px 0px 0px 0px;
  /* opacity: 0px; */
  outline: none;
  background-color:#ECECEC;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  font-size: 20px;
}

.loginpage .formPart form input::placeholder {
  text-align: right;
}

.loginpage .formPart form input:focus {
  box-shadow: none;
}

/* form button submit */


.loginpage .loaderIcon {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginpage .loaderIcon img {
  width: 150px;
  height: 150px;
}

/* New Style Design */
.formStyle {
  border: 1px solid #9FDAFE;
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background-color: #EDFAFF;
}

.links {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  margin-bottom: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.hr {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hr .line {
  width: 100%;
  height: 2px;
  border: 1px solid #BFBFBF;
  background-color: #BFBFBF;
}

.positionInput{
  position: relative;
}
.email{
  position: absolute;
  top:15%;
  left:5%;
  font-size: 25px;
}
/* New Style Design */

@media (max-width: 770px) {
  .loginpage .createAccount {
    width: 146px;
    height: 64px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 20px;
  }

  .loginpage .LoginBtn {
    width: 146px;
    height: 64px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 40px;
    padding: 10px;
    margin: 0 10px;
  }

  .loginpage .formPart form input::placeholder {
    font-size: 16px;
  }

  .loginpage .formPart form input {
    height: 50px;
  }
}