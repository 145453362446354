.fcplus-cont {
    width: 100%;
    height: 50px;
    position: relative;
    color: #0F2A51 !important;
}

.addPlus {
    color: #0F2A51 !important;
}

.form-row-n {
    margin-bottom: 20px;
}

.subm {
    background-color: #0F2A51 !important;
    color:white !important;
}

.subm:hover {
    color: #293476;
    color:white;
    margin-top: 50px;
    cursor: pointer;
}

@media screen and (max-width:700px) {
    .form-row-n {
        flex-direction: column !important;
    }
}