

.society .anyQuestion .comBox {
    padding: 40px;
}

.society .anyQuestion .comBox .anyQuestionTitle {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    color: #5c110b;
}

.society .anyQuestion .comBox .anyQuestionContent {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 10px;
    width: fit-content;
    padding: 5px 50px;
    background-color: #8c1c13;
    color: white;
    margin: auto;
    cursor: pointer;
    display: block;
    text-decoration: none;
}

.society .weekStd .anyQuestionTitle {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    color: #5c110b;
}

.society .weekStd .anyQuestionContent {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 10px;
    width: fit-content;
    padding: 5px 50px;
    background-color: #DFA73A;

    color: white;
    margin: auto;
    cursor: pointer;


}