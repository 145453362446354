.board-cont {
    display: flex;
    flex-direction: row;
    border: 0px solid;
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;
}

.board-taps {
    max-width: 284px;
    border-right: 1px solid #ccc;
    height: calc(100vh - 50px);
    background-color: white;
    overflow: auto;
    background-color: transparent;
    /* position: relative;                                                                                                                                      */
    border-left: 0.5px solid #001C45;
    border-right: 0.5px solid #001C45;
}

.board-content {
    /* display: block; */
    flex: 0.95;
    /* display: flex; */
    padding: 20px;
    flex-grow: 1;

    /* width: 1040px; */
    width: -webkit-fill-available;
    /* height: 95vh !important; */
    top: 233px;
    left: 64px;
    gap: 0px;
    border-radius: 8px;

    box-shadow: 3px 4px 4px 0px rgba(92, 17, 11, 0.25);
    border: 0.2px solid rgba(255, 253, 250, 1);
    margin-left: 22px;

}

.board-content-compress {
    /* width: calc(100vw - 210px); */
    /* height: 100vh !important; */
}

.board-content-full {
    /* width: calc(100vw - 2px); */
}

/* navigation-bar */
.navigation-bar {
    /* width: 100vw; */
    /* background-color: #283476; */
    height: 50px;
    /* box-shadow: 0 0 2px 2px #ccc; */
    position: relative;
}

.navigator-btn {
    position: absolute;
    left: 40px;
    background-color: rgba(92, 17, 11, 1) !important;
    border-radius: 19px;
}

.lessons-list {
    padding: 0px;
    width: 136px;
}

.lesson-tap {
    /* width: 260px;
    height: 60px;
    cursor: pointer;
    color: black;
    padding: 10px 20px 10px 20px; */
    font-family: Poppins;
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    text-align: center;
    color: #0E3F89;
    cursor: pointer;
}

.lesson-tap:hover {
    background-color: #ffffff;
}

.compress-btn {
    position: absolute;
    right: 10px;
    z-index: 100000;
}

#toolbar {
    display: none;
}

ifram #toolbar, viewer-toolbar {
    display: none;
}

:host {
    display: none
}


.lessontitle {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    color: rgba(92, 17, 11, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentTitle {
    color: #0E3F89;
    font-size: 20px;
}

.arrow {
    font-size: 20px;
    background-color: #0E3F89;
    padding: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}