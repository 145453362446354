.timeline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    position: relative;
}

.step {
    text-align: center;
    flex: 1;
    position: relative;
}

.circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f0f0f0;
    color: #555;
    line-height: 40px;
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
    font-size: 16px;
}

.active .circle {
    background-color: #002d72;
    /* Dark blue for active step */
    color: white;
}

.label {
    margin-top: 5px;
    font-size: 14px;
    color: #555;
}

.timeline::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #e0e0e0;
    z-index: -1;
}

.step:not(:first-child)::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -50%;
    width: 100%;
    height: 2px;
    background-color: #e0e0e0;
    z-index: -1;
}


.branchDiv, .levelDiv {
    border-radius: 10px;
    background: white;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 20vh !important;
    font-weight: 700;
    color: #071D5B;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    /* width:50%; */
}

.specialDiv{
    border-radius: 10px;
    background: white;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-height: 20vh !important; */
    font-weight: 700;
    color: #071D5B;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    width:50% !important;
}

.cardClass {
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    color: #071D5B;
    background: white;
}

.levelDiv {
    overflow: hidden;
    transform-origin: center;
    position: relative;
    background-image: url(../../assets/level.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-size 0.3s ease;
    -webkit-transition: background-size 0.3s ease;
    -moz-transition: background-size 0.3s ease;
    -ms-transition: background-size 0.3s ease;
    -o-transition: background-size 0.3s ease;
}

.levelDiv::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}

.levelDiv p {
    position: relative;
    z-index: 2;
    user-select: all;
}

.levelDiv:hover {
    background-size: 120%;
}



.branchDiv:hover, .cardClass:hover {
    color: white;
    background-color: #071D5B;
}

.branchDiv.active, .cardClass.active {
    color: white;
    background-color: #071D5B;
}

.imageLafta {
    position: relative;
}

.imageLafta .lafta {
    position: absolute;
    top: -90px;
    right: 0;
}

.prev {
    border: 0.5px solid #071D5B33;
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    -o-transition: 0.3s ease;
}

.prev:hover {
    background: #071D5B;
    color: white
}


.cardTeacher {
    overflow: hidden;
    text-align: center;
    background-color: white;
    padding: 16px;
    margin: auto;
}

.card-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
}

.card-content {
    padding: 10px 0;
    text-align: center;
    margin: auto;
}

.teacher-name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.lessons-count {
    font-size: 14px;
    margin-bottom: 10px;
}

.preview-button {
    cursor: pointer;
    text-decoration: none;
    color: black;
}


.rating {
    display: flex;
    justify-content: center;
    gap: 3px;
    margin-top: 5px;
}

.star {
    font-size: 18px;
    color: #ccc;
    /* Grey color for unfilled stars */
}

.star.filled {
    color: #ffa500;
    /* Orange color for filled stars */
}


/* course Details */
.course-page {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
    color: #333;
}

.course-details {
    width: 60%;
    padding: 20px;
    border-radius: 8px;
    text-align: right;
}

.course-title {
    font-size: 24px;
    font-weight: bold;
    color: #002d72;
}

.lessons-count {
    font-size: 18px;
    margin-top: 10px;
}

.section-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    color: #0041a3;
}

.course-description {
    margin-top: 10px;
    line-height: 1.5;
    color: #555;
}

.lesson-overview {
    margin-top: 20px;
}

.lessons-list {
    list-style: none;
    padding: 0;
    margin-left: auto;
}

.lessons-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.icon {
    margin-right: 10px;
    font-size: 20px;
}

.play-icon {
    color: green;
}

.lock-icon {
    color: #888;
}

.teacher-info {
    width: 35%;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.teacher-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
}

.teacher-details {
    padding: 10px 0;
}

.teacher-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.teacher-description {
    font-size: 14px;
    margin-bottom: 10px;
    color: #666;
}

.rating {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stars {
    display: flex;
    justify-content: center;
    gap: 3px;
    margin-bottom: 5px;
}

.star {
    font-size: 20px;
    color: #ccc;
    /* Grey color for unfilled stars */
}

.star.filled {
    color: #ffa500;
    /* Orange color for filled stars */
}

.student-count {
    font-size: 14px;
    color: #333;
}


.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    /* width: 300px; */
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.discount-tag {
    /* background-color: #ffb900; */
    color: white;
    padding: 4px 8px;
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.price-info {
    text-align: right;
}

.current-price {
    color: #333;
    font-size: 18px;
    font-weight: bold;
}

.original-price {
    text-decoration: line-through;
    color: #888;
    font-size: 14px;
}

.price-type {
    font-size: 12px;
    color: #888;
}

.card-content {
    /* display: flex;
    align-items: center; */
    /* margin: 10px 0; */
    text-align: left;
}

.instructor-photo {
    border-radius: 50%;
    margin-right: 10px;
    width: 50px;
    height: 50px;
}
.instructor-info{
    margin-top: -15px;
}
.instructor-info h4 {
    margin: 0;
    font-size: 16px;
}

.rating {
    font-size: 14px;
    color: #666;
}

.schedule,
.seats {
    font-size: 14px;
    color: #333;
    margin-top: 8px;
}

.card-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.details-btn {
    border: 1px solid #333;
    background-color: white;
    color: #333;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
}

.enroll-btn {
    background-color: #28a745;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}