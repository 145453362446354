.programs {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
}

.programs.show {
    max-height: 1000px; /* Adjust this value based on content size */
    opacity: 1;
}


@media(max-width:768px){
    .programsTeacher{
        height: 100vh;
        overflow: scroll;
    }
}