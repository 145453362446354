.modal-overlay{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #333;
    opacity: 0.7;
    z-index: 10000;
}
.modal-cont{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50% ,-50%);
    z-index: 10000;
    overflow-y:scroll;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 1px 1px 16px 0px #fff;
    padding: 10px;
}
/* .modal-cont div{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 15vh 20px 0px 20px;

} */
.top-bar{
  position: relative;
  width: 100%;
  height: 40px;
  border: 0px solid;
  z-index: 1000;
}

#close{
position: absolute;
top: 0px;
right: 0px;
background-color: transparent;
height: 4vw;
width: 4vw;
cursor: pointer;
transition: 0.7s;
border: 0;
}
           /* scroll bar  */
/* width */
/* ::-webkit-scrollbar {
    width: 10px;
  } */
  
  /* Track */
  /* ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  } */
   
  /* Handle */
  /* ::-webkit-scrollbar-thumb {
    background: #888; 
  }
   */
  /* Handle on hover */
  /* ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  } */
@media screen and (max-width:700px) {
  .modal-cont{
    width:95% !important;
}
#close{
  top: 0%;
  right: 40px;
  }
   
}