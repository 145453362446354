.meeting-cont{
    width: 98vw;
    height: 100dvh;
    display: block;
    position: relative;;
}
.loader-cont{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1;
}
.user-videos{
    position: absolute;
    top: 0;
    border: 1px solid;
    overflow-x: scroll;
}
.user-video{
    transform: scaleX(-1);
    width: 150px;
    height: 150px;
    
}
.my-video{
    transform: scaleX(-1);
    width: 200px;
    height: 200px;
}
.meeting-options{
    position: absolute;
    bottom: 0px;
    width: 99%;
    height: 11vh;
    border:1px solid red;
    z-index:2;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .join-button:hover{
    background: #8c1b1371;
  }
  .inetialize-my-video{
    width: 100%;
    transform: scaleX(-1);
  }