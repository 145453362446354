.level-row{
    display: flex;
    justify-content: space-around;
    margin: 10px 0 10px 0;
    background-color: gainsboro;
}
.branch-row{
    /* background-color: rgba(165, 42, 42, 0.653); */
    padding: 5px ;
    /* border: 1px solid orange  !important; */
    margin: 5px 0 5px 0;
}.custom-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 5px 0;
    min-width: 160px;
  }
  
  .dropdown-menu li {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .dropdown-menu li:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown-menu button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .dropdown-menu button:hover {
    color: #007bff;
  }
  