.profile-cont{
    display: block;
min-height: 70%;
border-radius: 12px;
    box-shadow: 0px 4px 23px 8px rgba(140, 140, 140, 0.09);
padding-top: 70px;
margin-top: 58px;
}
.teach-taps{
    display: flex;
    width: 100%;
    /* background-color: ; */
    justify-content: center;
}
.teacher-taps{
    display: flex;
 justify-content: center;
  /* border: 1px solid; */
  margin-top: 30px;
}
.teacher-taps a:hover{
    background-color: #283476;
    color: #fff ;
}
.teacher-taps a{
    display: inline-block;
    width: 203px;
    border: 1px solid #283476;
border-radius: 5px;
font-weight: 500;
font-size: 15px;
line-height: 55px;
text-align: center;
height: 58px;
margin: 0 31px 0 31px;
color: black;
text-decoration: none;
}
.activ{
    background-color: #283476;
    color: #fff !important;
}

/* .teacher-taps a:active{
    background-color: #283476 !important;
    color: #fff;
} */
.profile-details{
    
}
.prof-avatar{
    display: flex;
    text-align: start;
    margin: 39px;
    flex-wrap: wrap;
}
.prof-avatar img ,#profnnav{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: inline-block;
}
.prof-avatar div{
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 100%;
}
#profn1,#profn2{
    display: block;
    font-weight: 500;
}
#profn2{
    line-height: 19px;
    color: #525252;
}
#profn2{
   color: #AEAEAE;
font-size: 14px;
line-height: 17px;
}


.prof-form-wrap{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.prof-form-wrap div{
    width: calc(50% - 30px);
    /* border: 1px solid; */
    padding: 0 15px 0 15px;
    margin: 20px 0 20px 0;
}
.prof-form-wrap label{
    display: inline-block;
    margin: 0 15px 0 15px;
}
#prfoin1,#prfoin2,#prfoin3{
    /* display: inline-block; */
width:calc(100% - 150px) ;
height: 34px;
font-weight: 500;
font-size: 15px;
line-height: 18px;
/* background: #fff; */
border-radius: 40px;
margin:0 15px 0 15px;
padding:0 20px 0 20px
/* background-color: red; */
}
#prfoin5,#prfoin4{
border-radius: 40px;
height: 34px;
font-weight: 500;
font-size: 15px;
line-height: 18px;
width: 20%;
}
#prfoin6{

    background: #283476;
border-radius: 36px;
font-weight: 500;
font-size: 16px;
line-height: 30px;
color: #fff;
width: 200px;
margin:0 30px;
}