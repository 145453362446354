.caption{
    width: 100%;
    /* background-color: #293476; */
        font-size: 22px;
   color: white;
   padding: 10px;
   text-transform: uppercase;
}
.table-cell{
    width:50%;
    text-align: center;
}
.table-cont{
    width:95vw;
    overflow-x: scroll;
}
.table-cont table{
    border-spacing: 0px;
    text-align: center;
}
.table-cont tbody{
    background-color: rgba(217, 217, 217, 0.3);
    margin-bottom: 5px;
    font-size: 13px;
line-height: 16px;
/* identical to box height */

/* text-align: right; */

color: #413434;
}
.table-cont table td{
    border-spacing: 0px;
border:0px
}
.table-head{
    background-color: #293476;
    color: #fff;
}
.table-head{
    border: 0px;
}