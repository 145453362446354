.products-list{
    font-size: 20px;
    list-style: none;
    width:100%;
}
.products-list li:hover{
    background-color: rgba(0, 0, 255, 0.445);

}
.products-list li{
    /* cursor: pointer;    */
     height: fit-content;
     border-radius: 5px;
}
.modal-product-details {
    list-style:circle;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width:100%;
    height: 100%;
}
.p-img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 0px solid;
    box-shadow: 0px 0px 3px 0px rgb(10, 0, 0);
}
.icon{
    cursor: pointer;

}