
.stdResults .oneBoxStd {
  position: relative;
  padding: 0px, 42px, 0px, 0px;
  border-radius: 10px;
  border: 0.54px;
  /* border: 0.54px solid #dfa73a; */
  /* box-shadow: 4px 5px 2.4000000953674316px 0px #5c110bab; */
  padding: 20px 30px;
}

.stu_rightSideLinx {
  width: 188px;
  height: 7px;
  border-radius: 7px;
  background-color: #5c110b;
}

.stdResults .fiftyPercent {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: #5c110b;
  margin-top: 10px;
}

.stdResults .stu_rightSideRes {
  text-align: right;
}

.stdResults .stu_rightSideRes p {
  margin-bottom: 5px;
}

.stdResults .stu_rightSideRes .proResult {
  text-align: right;
  color: #0F2A51;
}

.stdResults .stu_rightSideRes .class {
  text-align: right;
}

.stdResults .stu_rightSideRes .hour {
  text-align: right;
}

.stdResults .resTextAndImage .oneBoxRes {
  text-align: center;
}

.stdResults .resTextAndImage .oneBoxRes img {
  width: 149px;
  height: 139px;
}

.stdResults .resTextAndImage .oneBoxRes .oneBoxResTitle {
  color: #0F2A51;
}

.stdResults .twoDivs .resItems {
  padding: 18px, 10px, 18px, 10px;
  border-radius: 8px;
  border: 1px solid #dfa73a;
  box-shadow: 7px 5px 7.5px 0px #dfa73a;
}

.stdResults .twoDivs .itemsTile {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: right;
  color: #5c110b;
  text-align: center;
  margin: 10px 0;
  border-bottom: 2px solid #dfa73a;
}

.stdResults .twoDivs .subResult .oneSub {
  padding: 0 10px;
}

.stdResults .twoDivs .subResult .oneSub .linSub {
  width: 110px;
  height: 5px;
  background-color: #dfdfdf;
  border-radius: 2px;
}

.stdResults .twoDivs .subResult .oneSub .subResLine {
  width: 50%;
  height: 100%;
  background-color: #dfa73a;
}

.stdResults .twoDivs .subResult .oneSub .oneSubTitle {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: right;
}

.stdResults .twoDivs .resItemsLeft {
  padding: 18px, 10px, 18px, 10px;
  border-radius: 8px;
  border: 1px solid #dfa73a;
  box-shadow: 7px 5px 7.5px 0px #bf9573;
  text-align: center;
  padding: 20px 0 40px;
}

.stdResults .twoDivs .resItemsLeft .itemsTileLeft {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #5c110b;
  margin: 10px 0;
}



.stdResults .boxEmptyStat {
  border-radius: 8px;
  border: 1px solid #dfa73a;
  padding: 30px 0;
}

.stdResults .boxEmptyStat .emptyTitle {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #5c110b;
}

.stdResults .boxEmptyStat .emptyContent {
  width: fit-content;
  padding: 10px 50px;
  border-radius: 8px;
  background: #8c1c13;
  color: white;
  margin: auto;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

/*********** table*********************/
.stdResults table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  direction: rtl;
  border: none;
}

.stdResults th {


}

.stdResults tr {
  /* border: 1px solid #DFA73A !important; */
  text-align: center;
  padding: 15px;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  border-top: none !important;
  /* background-color: #faf2e2; */
  color: black;
  line-height: 45px;
}

.stdResults tr:last-child {
  border-bottom: none !important;
}


.stdResults td {
  text-align: center;
  padding: 15px;
 
  /* gap: 10px; */

}

.th_Results {
  font-size: 16px;
}

.numOfQuestionBox {
  border-radius: 8px;
  border: 1px;
  border: 1px solid #2B6CCD;
  color: #0F2A51;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  background-color: white;
  cursor: pointer;
  font-size: 16px !important;

}

.bodyTableExam{
  font-size:18px !important;
  font-family: "Cairo", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-variation-settings:
    "slnt"0 !important;
}

/************table end********/

.selectedTest {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  text-align: center;
  color: rgba(92, 17, 11, 1);
}

.selectedTest .title {
  width: 525px;
  height: 46px;
  gap: 0px;

}

.selectedTest .question {
  height: 90px;
}

.btn-style {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
  color: white;
  border: none;
  width: 210px;
}

.true {
  background: rgba(59, 120, 49, 1);
}

.false {
  background: rgba(158, 65, 54, 1);
}

@media (max-width: 995px) {
  .stdResults .oneBoxStd .imageVect {
    position: absolute;
    top: 0;
    right: 100px;
  }

  .stdResults .parentImg {
    width: 80px;
    height: 80px;
  }

  .stdResults .techName {
    font-size: 15px;
  }

  .stdResults .teachContact {
    width: 140px;
    height: 40px;
    line-height: 40px;
  }

  .stdResults .stu_rightSideRes {
    margin-right: 5px;
    margin-top: 40px;
  }

  .stdResults .stu_rightSideRes .stu_rightSideLin {
    width: 80px;
    height: 7px;
  }

  .stdResults .stu_rightSideRes .pro {
    font-size: 20px;
  }

  .stdResults .stu_rightSideRes .class {
    font-size: 18px;
  }

  .stdResults .stu_rightSideRes .hour {
    font-size: 14px;
  }
}


@media (max-width:440px) {
  .selectedTest .title {
    width: auto;
  }

  .smallwidth {
    width: 280px;
    /* overflow-x: scroll; */
    height: 69px;
  }

  .btn-style {
    width: 78px;
    font-size: 14px;
  }

  .stdResults .stu_rightSideRes .proResult {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: right;
  }

  .stdResults table {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;

  }

  .th_Results {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
  }

  .stdResults th, .stdResults tr, .stdResults td {
    font-size: 14px;
  }

  .selectedTest {
    font-size: 14px;
  }
}