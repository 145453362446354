.add-qu-cont{
    width: 100%;
    text-align: center;
    padding: 40px 0 40px 0;
}


.allPosts{
    background-color: #0F2A51 !important;
    text-decoration: none;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.post-card{
    display: block;
    /*height: 283px;
    */width: 100%;
    /* box-shadow: 0 0 3px 1px #0F2A51; */
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: white;
}
.image-cont-post-card{
    display: flex;
    flex-wrap: wrap;
    
    width: 100%;
}
.avatar-post{
    width: 150px;
    display: block;
}

.image-cont-post-card img{
    object-fit: cover;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 1px solid;
}
.post-author{
    
    height: 90px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    
}
.post-author h4{
    color: #0F2A51;
    font-size: 20px;
}
.post-author span{
    font-size: 12px;
    color: gray;
}
.post-content{
    padding: 40px;
    color: #0F2A51;
    font-size: 17px;
}
.post-view-btn{
    text-decoration: none;
    display: inline-block;
    color:white;
    background-color: #0F2A51;
    border: 1px solid #0F2A51;
    border-radius: 10px;
    font-size: 15px;
    padding: 10px;
}
