/* first part style leftSide */
input, select {
  background-color: #ECECEC !important;
  padding: 15px;
  margin-bottom: 15px !important;
}



/* New Style */
.registerStudentForm {
  background: #EDFAFF;
  border: 1px solid #9ED9FE;
  /* text-align: right; */
  margin-top: 50px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 15px;
}

.hint, .rulesPara1 {
  color: gray;
  font-size: 11px;

}

.error_field {
  border: 1px solid red !important;
}

.formregister {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.arrow {
  display: none !important;
}

.form-control {
  width: 100% !important;
}

@media (max-width: 770px) {
  .registerPage .createAccount {
    width: 146px;
    height: 64px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 20px;
  }

  .registerPage .LoginBtn {
    width: 146px;
    height: 64px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 40px;
    padding: 10px;
    margin: 0 10px;
  }

  .registerPage .paertStuden {
    justify-content: center;
  }

  .registerPage .studentTab,
  .registerPage .parentTab {
    width: 110px;
    height: 40px;
    font-size: 20px;
    padding-top: 0;
  }

  input, select {
    font-size: 16px !important;
  }

  .rules {
    font-size: 16px !important;
  }
}