/* shared */
.tex {
    width: 90%;
    height: 30px;
    margin-bottom: 10px;
    
}
.create-form label{
   text-align: start;
   width: 90%;
   /* display: block; */
}

 .create-form h3{
  font-size: 1vw;  
  position: absolute;
  width: 100%;
  top: 50%;
  right: 5%;
  background-color:  #CBA980;
  color: aliceblue;
  padding:5px 5% 5px;
  margin-top: 3%;
 }

 .create-form input[type=submit]{
    
    width: 80px;
    height: 25px;
    background-color:#D0212B;
    border: 1px solid;
    font-size: 18px;
    margin: 10px;
    color: white;
    padding: 3px;
    border-radius: 4px;
 }
 .create-form input[type=submit]:hover{
    color: #293476;
    border: 1px;
    cursor: pointer;
 }
 .create-form{
      height: 100% ;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content:flex-start;
      border: 1px solid #D0212B;
      overflow: scroll;
      padding: 10px;
 }
 /* account-type-list */
 #account-type-list{
   width: 95%;
   margin : 10px 0 15px 0;
   background-color:  #293476;
   color: white;
   padding: 5px;
   border:1px solid #D0212B;
   border-radius: 5px;
   cursor: pointer;
   
 }
 .sub-category-title{
   background-color: #D0212B;
   width: 60%;
   text-align: center;
   color: #fff;
   padding: 4px;
   border-radius: 4px;
 }

 