.view-area-cont {
    width: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.pdf-container {
    width: 100%;
    height: 200vh;
}

.pdf-object {
    width: 100%;
    height: 100%;
}

.form-check-input{
    margin-top: -5px !important;
}