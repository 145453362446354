
.sidnav-containerr {
  position: relative;
}


.sidnave {
  display: none; 
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  background: white;
  color: white;
  padding: 20px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.sidnave.open {
  transform: translateX(0);
  background: white !important;
}

.menu-togglee {
  display: none; 
  position: fixed;
  top: 250px;
  left: 15px;
  z-index: 1200;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
}

/* Tabs (Desktop Only) */
.tabs-containers {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  padding: 10px;
}

.tabs-containers a {
  text-decoration: none;
  color: #2c6dce;
  padding: 10px 15px;
  border-radius: 4px;
  transition: background 0.3s;
}

.tabs-containers a.active-tab {
  background: #2c6dce;
  color: white;
}


@media (max-width: 768px) {
  .sidnave {
    display: block;
  }

  .menu-togglee {
    display: block;
  }

  .tabs-containers {
    display: none; 
  }
}
