.chat-icon {
    position: fixed;
    bottom: 5px;
    right: 10px;
    z-index: 100;
    cursor: pointer;
    animation: zoomInOut 2s infinite;
}

@keyframes zoomInOut {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}
