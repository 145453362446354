.dashboard-nav {
    background: var(--primary-color);
    height: 70px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.menu-btn {
    background: none;
    border: none;
    font-size: 24px;
    color: var(--text-color);
    cursor: pointer;
}

.nav-center {
    display: flex;
    align-items: start;
    justify-content: start;
}

.profile-img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
}

.nav-links {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.nav-link {
    color: var(--text-color);
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    transition: background 0.3s;
}

.nav-link:hover {
    background: var(--hover-color);
}

.logout-btn {
    width: 100%;
    padding: 10px;
    background: var(--danger-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Add these styles */
.offcanvas-nav-links {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
  }
  
  .offcanvas-nav-link {
    text-decoration: none;
    padding: 12px 20px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  
  .offcanvas-nav-link:hover {
    background-color: #f5f5f5;
  }
  
