.header-card {
    border-bottom-left-radius: 80px;
    border-bottom-right-radius: 80px;
    height:150px;
    color:white;
    background:#2B6CCD;
    padding:10px;
}
.img-card{
    margin: -120px auto 10px auto;
}


.card.light {
    background-color: white;
    color: #000;
}

.card.dark {
    background-color: #96BFFF;
    color: #fff;
}
