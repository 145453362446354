.card {
    width: 80%;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.card-image {
    width: 100%;
    height: auto;
}

.card-content {
    padding: 15px;
    text-align: right;
}

.card-content h3,
.card-content h4 {
    margin: 0;
    padding: 0;
}

.card-content p {
    margin: 10px 0;
    color: #777;
}

.card-details {
    margin: 10px 0;
}

.card-details span {
    display: block;
    margin-bottom: 5px;
}

.card-buttons {
    display: flex;
    justify-content: start;
}

.details-button,
.book-button {
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    margin-left: 10px;
}

.details-button {
    background-color: #f0f0f0;
    color: black;
}

.book-button {
    background-color: #2B6CCD;
    color: #fff;
}

.card.light {
    background-color: white;
    /* الخلفية للوضع النهاري */
    color: #000;
    /* لون النص للوضع النهاري */
}

.card.dark {
    background: linear-gradient(180deg, #07224D 0%, #1E3465 100%);
    ;
    color: #fff;
}


/* Program Details */
.text-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.text-container div {
    margin: 10px auto;
}

.forSubscribers {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width:768px) {
    .card {
        width: 90%;
        border: 1px solid #ccc;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin: 20px;
    }

    .forSubscribers {
        flex-direction: column;
    }

    .forSubscribers div {
        margin-bottom: 20px;
    }
}