.filterAdmin{
    display: flex;
    width: 100%;
    height: 100px;
    flex-wrap: wrap;
}
.filterAdmin input{
    position: initial !important;
    display: inline-block !important;
height: 30px;
}