.user-dashboard {
    display: grid;
    grid-template-areas: 'head head head''. body  .';
    /* grid-template-columns: 100px calc(100% - 200px) 100px; */
    grid-template-rows: 100px auto;
}

.user-dashboard th {
    font-size: 15px !important;
}

.user-dashboard td {
    font-size: 12px;
}

.user-dashboard tr, .user-dashboard td, .user-dashboard th {
    height: fit-content !important;
}

.user-dash-header {
    display: block;
    grid-area: head;
    /* width: 100vw; */
}

.user-dash-body {
    grid-area: body;
    display: grid;
    /* border: 1px solid; */

    grid-template-areas: "subhead subhead subhead""control . subbody";
    grid-template-rows: 50px calc(100vh - 50px);
    grid-template-columns: 178px 62px calc(100% - 240px);
    /* overflow: scroll; */
}

.subhead {
    grid-area: subhead;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
    text-align: start;
    padding: 0 28px 0 28px;
    background: #120C54;
    border-radius: 5px;
    /*transform: matrix(-1, 0, 0, 1, 0, 0);
    */
    -webkit-border-radius: 5p;
    -moz-border-radius: 5p;
    -ms-border-radius: 5p;
    -o-border-radius: 5p;
}

#suh1 {
    display: inline-block;
    margin: 0 10px 0 10px;
}

.controlmenu {
    grid-area: control;
    /* border: 1px solid red; */
    height: 100%;
    width: 100%;
    padding: 0 7px 0 7px;
    box-shadow: 0px 4px 23px 8px rgba(140, 140, 140, 0.09);
}

#sub-body {
    grid-area: subbody;
    width:70vw;    
    margin: auto;
}

@media only screen and (max-width:900px) {
    .user-dashboard {
        grid-template-columns: 20px calc(100vw - 40px) 20px;

    }

    .user-dash-body {
        grid-template-areas: "subhead subhead subhead""subbody subbody subbody"" control control control";
    }
}