/* Footer Design */

a {
    text-decoration: none;
    color: white;
}

.footer {
    background-color: #4184BF;
    padding: 20px;
}

.widget ul {
    list-style-type: none;
}

.widget ul li {
    text-align: right;
}


.widget-title {
    font-weight: 700;
    margin-bottom: 10px;
}





/* Header && header Actions  */

.loginBtn {
    background-color: #4184BF;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 10px;
    color: white;
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    -o-transition: 0.3s ease;
}

.loginBtn:hover {
    background-color: #4184BF;
}

.languageSwitcher {
    border-radius: 10px;
    padding: 2px 8px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border: none;
    outline: none;
}

.actionQuickLinks{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quickLinks{
    display: none !important;
}

.ps-list--link li , .ps-list--social p{
    color: white;
}
.ps-list--link li span{
    color: white !important;
}
/* navigation Default */

.header {
    width: 100%;
}

.navbar {
    position: sticky;
    top: 0;
}

.navigatedList {
    color: black;
}

@media(max-width:796px){
    .navigatedList{
        font-size: 10px;
        font-weight: 900;
    }
    .actionQuickLinks{
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }
    .actionQuickLinks div{
        margin-top: 15px;
    }
    .quickLinks{
        display: block !important;
    }
    .linkCart , .linkFav{
        display: none;
    }
    
}