#userIMG {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: gray;
}

#teach-header {
    border-radius: 20px 20px 0px 0px;
    background-color: #ffffff;
    display: grid;
    grid-template-areas: 'list  action';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 70px;
    grid-gap: 80px;
    color: #333;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.06);

}

#teach-header a {
    text-transform: capitalize;
    color: #333;
}

.action-list-b {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    list-style: none;
    padding: 0px 0px 0 0;
    grid-area: action;
    justify-content: flex-end;
}

#list-b {
    width: 70px;
    display: flex;
    flex-wrap: wrap;
}

/* section 2 */
.absec2-1 {
    width: 40%;
    /* justify-content: flex-start; */
    align-items: start;
    text-align: start;
    flex-direction: column;
    margin: 50px 0 50px 0;

}

.absec2-1 span {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    color: #D82839;
}
.absec2-1 h3{
    font-weight: 700;
font-size: 30px;
line-height: 49px;
color: #283476;
}
.absec2-1 p{
    font-weight: 400;
font-size: 18px;
line-height: 22px;
color: #4B4B4B;
}
#absec2but{
    font-size: 18px;
line-height: 40px;
color: #FFFFFF;
text-decoration: none;
display: inline-block;

background: #283476;
border-radius: 50px;
width:40%;
text-align: center;
}
.absec2-2{
    width: 50%;
    margin: 10px 0 50px 0;
}
.absec2-icons{
    display: flex;
    flex-wrap: wrap;
    height: 50vh;
    width: 90%;
    /* border: 1px solid; */
    position: relative;
}
.absec2st{
    width: 44%;
    height: 80%;
    /* border: 1px solid; */
margin: 12% 3% 0 3%;

}
.absec2end{
    /* border: 1px solid; */
margin: 0 3% 0 3%;
    width: 44%;
    height: 80%;
}
.absec2-icon{
    width: 95%;
    height: 48%;
    /* border: 1px solid; */
    text-align: center;
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.06);
border-radius: 15px;
margin: 5% 2% 10% 2%;
}
.absec2-icon img{
width: 40%;
height: 40%;
padding-top: 10px;
}
.absec2-icon h4{
    font-weight: 700;
    font-size: 19px;
    line-height: 22px;
    color: #0C0D24;
}
@media only screen and (max-width:750px){
   .about-sec2-sub-cont{
    flex-direction: column-reverse;
   }
    .absec2-2,.absec2-1{
        width:100%
    }
    .absec2-1{
text-align: center;
align-items: center;
    }
    .absec2-1 h3{
        font-size: 18px;

    }
    .absec2-1 p{
        padding: 0 20px 0 20px;
        font-size: 13px;
    }
    #absec2but{
        font-size: 15px;
        width: 35%;
        line-height: 30px;
    }
    .absec2-icon h4{
        font-size:13px ;
    }
    
}