.message-top-bar{
    background-color: #293476 !important;

}
.messages-body-cont{
    background-color: #fff;
}
.message-top-bar button{
    background-color: transparent;
    width: 90%;
    border:0px solid #D0212B;
    color:#ffffff;
    cursor: pointer;
    line-height: 30px;
    box-shadow: 0 0 2px #fff;
    border-radius: 4px;
}

.message-top-bar button:hover{
  background-color:#D0212B;
}
.message-header{
    padding: 10px;
    font-size: 15px;
    font-style: italic;
    color:#293476;
    background-color: #293476;
}
.messages-cont{
    width: 100%;
    display: block;
}
.message-subject{
width:90%;
text-align:left;
font-weight: 900;
font-size: 30px;
color:#D0212B

}
.message-content{
    width:90%;
    line-height: 20px;
    text-align: justify;
    border:1px solid #D0212B;
    min-height: 300px;
    padding: 10px;
}
.view-message{
    cursor: pointer;
    /* color: #293476; */
}
.reply{
    cursor: pointer;
}