
/* .leaderboard {
    max-width: 790px;
    width: 100%;
    border-radius: 12px;

  }
  .leaderboard header {
    --start: 15%;
    height: 130px;
    background-image: repeating-radial-gradient(circle at var(--start), transparent 0%, transparent 10%, rgba(54, 89, 219, 0.33) 10%, rgba(54, 89, 219, 0.33) 17%), linear-gradient(to right, #5b7cfa, #3659db);
    color: #fff;
    position: relative;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
  } */
  /* .leaderboard header .leaderboard__title {
    position: absolute;
    z-index: 2;
    top: 50%;
    right: calc(var(--start) * 0.75);
    transform: translateY(-50%);
    text-transform: uppercase;
    margin: 0;
  } */
  /* .leaderboard header .leaderboard__title span {
    display: block;
  }
  .leaderboard header .leaderboard__title--top {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 6.5px;
  } */
  /* .leaderboard header .leaderboard__title--bottom {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 3.55px;
    opacity: 0.65;
    transform: translateY(-2px);
  }
  .leaderboard header .leaderboard__icon {
    fill: #fff;
    opacity: 0.35;
    width: 50px;
    position: absolute;
    top: 50%;
    left: var(--start);
    transform: translate(-50%, -50%);
  }
  .leaderboard__profiles {
    background-color: #fff;
    border-radius: 0 0 12px 12px;
    padding: 10px 10px 10px;
    display: grid;
    row-gap: 8px;
  } */
  .leaderboard__profile {
    width: 96%;
    border:0px solid;
    display: grid;
    grid-template-columns: 10% 15% 30% 20% 5% 5%;
    align-items: center;
    column-gap: 6px;
    padding: 10px 10px 10px 10px;
    overflow: scroll;
    border-radius: 10px;
    box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
    /* cursor: pointer; */
    transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98), box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
    background-color: #fff;
    margin-bottom: 7px;
  }
  .leaderboard__profile:hover {
    transform: scale(1.0001);
    box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
  }
  .leaderboard__picture {
    /* max-width: 100%; */
    width: 8%;
    border-radius: 50%;
    box-shadow: 0 0 0 10px #ebeef3, 0 0 0 22px #f3f4f6;
  }
  .leaderboard__name {
    color: #979cb0;
    font-weight: 500;
    font-size: 1.5vw;
    letter-spacing: 0.64px;
    margin-left: 10px;
    width: 10%;
  }
  .leaderboard__value {
    color: #1f0dc2;
    font-weight: 700;
    font-size: 34px;
    text-align: right;
    width:10%
    /* text-decoration: underline; */
    
  }
  .leaderboard__value > span {
    /* float: left; */
    opacity: 0.8;
    font-weight: 500;
    font-size: 12px;
    margin-left: 3px;
  }
  

  .leaderboard {
    box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.4);
  }
  @media only screen and (max-width:750px){
    .leaderboard__name {
      font-size: 8px;
    }
    .leaderboard__value span{
   font-size: 8px;
    }
    .leaderboard__profile{
      padding: 0%;
      gap:0px
    }
  }