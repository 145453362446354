.TeacherClassesComponent {
    width: auto;
    top: 576px;
    margin-left: 48px;
    display: flex;
    flex-direction: column;
    gap: 13px;
    border-radius: 5px 0px 0px 0px;
    margin-bottom: 75px;
}

.TeacherClassesComponent .classBox {
    width: auto;
    padding: 26px 33px 26px 33px;
    display: flex;
    gap: 10px;
    margin: 35px;
    border-radius: 8px;
    background: #0F2A51 !important;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding:10px;
}

.classBoxContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.classBox .box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 217px;
    gap: 357px;
}

.addclass_btn {
    background-color: #2B6CCD; 
    padding:10px;
    border-radius:5px ;
    border: none;
    outline: none;
    -webkit-border-radius:5px ;
    -moz-border-radius:5px ;
    -ms-border-radius:5px ;
    -o-border-radius:5px ;
    color:white;
    margin-top: 10px;
}

.addclass_btn:hover {
        
}

.meeting_btn {
    width: 278px;
    height: 62px;
    border-radius: 8px;
    text-decoration: none;
    border: 1px solid rgba(92, 17, 11, 1);
    margin-top: 10px;
    gap: 0px;
    background-color: white;
    padding: 4px;
}

.meeting_btn:hover {
    background-color: rgba(92, 17, 11, 1);
    color: #F9EDD8;


}

.font-meeting_btn {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    text-align: center;
    color: rgba(92, 17, 11, 1);

}

.font1 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    text-align: right;
    color: rgba(92, 17, 11, 1);
}

.font2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: right;
    color: rgba(39, 39, 39, 1);
}

.font-addclass_btn {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
    text-align: center;

}

.classBoxContainer_supervisor {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1000px) {
    .font1 {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: right;
    }

    .TeacherClassesComponent .classBox {
        width: 95% !important;
    }

    .font2 {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;

    }

    .addclass_btn,
    .meeting_btn {
        width: 156px;
        height: 45px;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 800;
        line-height: 21px;
        text-align: center;

    }

    .TeacherClassesComponent .classBox {
        /* justify-content: center; */
        gap: 0px;

    }

    .classBox .box {
        /* justify-content: center; */
        gap: 0px;
    }

    /* .addclass_btn{
        width: auto;
    } */
    


    @media (max-width:500px) {
        .font1 {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: right;
        }

        .font2 {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;

        }

        .TeacherClassesComponent {
            width: 100%;
            margin-left: 0px;
            height: fit-content;
            display: flex;
            justify-content: start;
            align-items: center;
        }

        .TeacherClassesComponent .classBox {
            /* width: 166px; */
            height: inherit;
            gap: 10px;
            border-radius: 8px 0px 0px 0px;
            border: 1px 0px 0px 0px;
            /* opacity: 1; */
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .classBox .box {
            /* width: 100px; */
            /* min-height: 216px; */
            height: auto;
            gap: 10px;
            border-radius: 8px 0px 0px 0px;
            border: 1px 0px 0px 0px;
            /*  */
            display: flex;
            flex-direction: column;
        }

        .addclass_btn,
        .meeting_btn {
            width: 156px;
            height: 45px;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 800;
            line-height: 21px;
            text-align: center;

        }

        .classBoxContainer {
            /* width: 110px; */
            /* padding-top: 10px; */
        }

        .classBoxContainer_supervisor {
            flex-direction: column;
        }
    }

}