.TypeOne, .Typetwo {
    background-color: #2B6CCD;
    color: white;
    text-align: center;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: 60%;
    margin: auto;
    min-height: 40vh;
    background-image: url(../assets/CIRCELS.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.infoService {
    width: 50%;
    margin: auto;
}

.package-card {
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;
    font-family: 'Tajawal', sans-serif;
}

.package-title {
    font-size: 22px;
    font-weight: bold;
    color: #1A73E8;
    margin-bottom: 10px;
}

.package-description {
    font-size: 14px;
    color: #5f6368;
    margin-bottom: 20px;
}

.package-features {
    list-style-type: none;
    padding: 0;
    margin: 0 0 20px 0;
}

.package-features .feature {
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 14px;
    margin-bottom: 10px;
}

.feature.active:after {
    content: '✔️';
    color: #1A73E8;
    margin-left: 10px;
}

.feature.inactive:after {
    content: '✔️';
    color: lightgray;
    margin-left: 10px;
}

.package-price {
    font-size: 18px;
    font-weight: bold;
    color: #1A73E8;
    margin-bottom: 20px;
}

.select-package {
    background-color: #1A73E8;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.select-package:hover {
    background-color: #1766c2;
}


/* Subs */



@media(max-width:768px) {
    .infoService {
        width: 90%;
    }

    .TypeOne, .Typetwo {
        margin-top: 15px;
    }
}