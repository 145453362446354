.disscount-form-cont {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disscount-form-in {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 30vw;
    height: 500px;
    border: 0px solid;
    padding-top: 50px;
    box-shadow: 0px 0px 16px 9px rgba(0, 0, 0, 0.07);
}

.disscount-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* width: 30vw; */
    /* height: 500px; */
    border: 0px solid;
    padding-top: 40px;
    /* box-shadow: 0px 0px 16px 9px rgba(0, 0, 0, 0.07); */

    /* display: grid;
    grid-template-areas:". . ."". mess ." ". c ." ". am ." ". s .";
    gap:20px;
    grid-template-rows:50px 100px 50px 50px 30px;
    grid-template-columns: 1fr 250px 1fr; */
}

.diss-in-sub {
    display: flex;
    width: 100%;
    /* border: 1px solid;  */
    flex-wrap: wrap;
    margin: 0 0 20px 0;
}

.disscount-form-in input {
    width: 75%;
    margin: 0 12% 32px 12%;
    border: 1px solid rgba(40, 52, 118, 0.24);
    border-radius: 40px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #5E5E5E;
    height: 40px;
    padding: 0 0 0 10px;
}

.diss-in-sub .c {
    display: inline-block;
    width: 40%;
    background-color: #EFEFEF;
    border-radius: 4px;
    font-size: 15px;
    line-height: 40px;
    color: #969696;
    padding-inline-start: 13px;
}

#signraddiss {
    width: 40px;
    height: 40px;
    margin: 50px;
}

.disscount-form .s {

    cursor: pointer;
    /* or 356% */
}

.s:hover {
    background: #283476;
    color: #fff;
}

.disscount-form span {
    display: block;
    width: 100%;
    height: 35px;
    text-align: center;
}

.disscount-form-in span {
    display: block;
    width: 100%;
    height: 35px;
    text-align: center;
}

.disscount-form-in label {
    margin: 0 12% 0px 12%;
}

/* .c{
   grid-area: c;
} */
/* .am{
    grid-area: am;
}
.s{
    grid-area: s;
    width: 100px;   
} */

.mess {
    grid-area: mess;
    display: inline-block;
    text-align: center;
    color: red;
    width: 250px;
    text-align: start;
}
/* disscount list */
.code-row{
    display: flex;
    flex-wrap: wrap;

}
.code-row span{
    display: inline-block;
    width : 20%;
}