.contact {
    margin-top: 120px;
}

.contactDiv {
    position: relative;
}

.sharedImage{
    width:75%;
}
.infoContact {
    position: absolute;
    top: 22%;
    right: 15%;
}

.infoContact div {
    background-color: #120C54;
    color: white;
    margin: 20px auto;
    text-align: center;
    padding: 12px;
    font-size: 18px;
}


.contactForm {
    margin-top: 10px;
}

ul li span {
    color: #3172D3;
}

ul li {
    color: #011334;
    margin-bottom: 15px;
}

.formContactData {
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.contactForm input,.contactForm textarea {
    border: 1px solid #8F8F8F;
}

.contactForm .textarea {
    background-color: #e8e0e0 !important;
    text-align: right;
}
.divBtn{
    text-align: center;
}
.sendNow{
    background: linear-gradient(180deg, #2C6DCE 0%, #3778D9 100%);
    width: 75%;
    margin: 15px auto;
}



@media(max-width:767px){
    .infoContact {
        position: absolute;
        top: 25%;
        right: 5%;
    }
    .infoContact div {
        background-color: #120C54;
        color: white;
        margin: 10px auto;
        text-align: center;
        padding: 15px;
        font-size: 16px;
    }
    .sharedImage{
        width:100%;
    }

    .handleRow{
        flex-direction: column-reverse;
    }
}