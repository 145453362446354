.divProfile {
    margin: -35px auto;
    text-align: center;
}

.dashboard {
    margin-bottom: 130px;
}


/* Profile Design */
.profileDetails {
    padding: 10px;
    border-radius: 10px;
    color: white;
    text-align: right;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: #0E3F89;
}


.listProfile ul li {
    color: white;
    /* list-style: circle; */
}

input {
    background-color: white !important;
}

.emptyComponent {
    text-align: center;
}

.subscripeNow {
    width: 20%;
}


/* Style Card Material */
.material .card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    max-width: 300px;
    text-align: center;
    font-family: 'Arial, sans-serif';
}

.material .card-img {
    width: 100%;
    height: auto;
}

.material .card-content {
    padding: 15px;
}

.material .card-content h2 {
    margin: 15px 0;
    color: #007BFF;
}

.material .card-content p {
    color: #555;
    margin-bottom: 15px;
}

.card-buttons {}

.material .card-btn {
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.material .card-btn:hover {
    background-color: #0056b3;
}

.titleMaterialCard {
    color: white;
    margin-top: -10px;
}


/* Messages */
.message-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;
    max-width: 800px;
    margin: 20px auto;
    font-family: 'Arial, sans-serif';
    direction: rtl;

}

.message-time {
    font-size: 12px;
    color: #999;
}

.message-content-dashed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    flex: 1;
    justify-content: flex-end;
    width: 50%;
}

.message-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.message-info {
    text-align: right;
}

.message-info h4 {
    margin: 0;
    font-size: 16px;
    color: #007BFF;
}

.message-info p {
    margin: 0;
    font-size: 14px;
    color: #555;
}



/* Add this to your CSS file */
.form-container {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 2.5s ease, max-height 0.5s ease;
    -webkit-transition: opacity 1.5s ease, max-height 0.5s ease;
    -moz-transition: opacity 2.5s ease, max-height 0.5s ease;
    -ms-transition: opacity 2.5s ease, max-height 0.5s ease;
    -o-transition: opacity 2.5s ease, max-height 0.5s ease;
}

.form-container.show {
    opacity: 1;
    max-height: 1000px;
    /* A large enough value to ensure the form is fully visible */
}


/* Design Modal Subject Details Material Details */
.modal {
    background-color: radial-gradient(25.31% 24.98% at 0% 15.35%, #CCDFFF 0%, #ECF3FF 90%) !important;
    ;
}

.modalMaterial .program-card {
    text-align: center;
    font-family: 'Arial', sans-serif;
}

.modalMaterial .header {
    background-color: #0056b3;
    color: white;
    padding: 20px;
}

.modalMaterial .cards-container {
    padding: 10px;
}

.modalMaterial .card {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    margin: auto;
    text-align: left;
}



.modalMaterial .progress {
    height: 100%;
}

.modalMaterial button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.modalMaterial button:hover {
    background-color: #0056b3;
}

.headTitle {
    background-color: #0E3F89;
    padding: 5px 15px;
    color: white;
}


.sidnav {
    width: 75%;
    text-align: center;
    margin: 80px auto;
    color: red;
}

.side-menu {
    display: flex;
    margin-top: 5rem;
    justify-content: space-between;
    text-align: center;
    flex-wrap: wrap;
}

.side-menu a {
    text-decoration: none;
}

.active-link {
    font-weight: bold;
    border-bottom: 2px solid currentColor;
}

.active-indicator {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: currentColor;
    border-radius: 50%;
    margin-left: 5px;
}

@media (max-width: 768px) {
    .sidnav {
        width: 100%;
        margin: 20px auto;
    }

    .side-menu {
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap !important;
    }

    .side-menu a {
        margin-bottom: 1rem;
    }

    .message-card {
        width: 100% !important;
    }

    .message-content-dashed {
        width: 100% !important;
    }
}

@media (max-width: 480px) {
    .sidnav {
        font-size: 14px;
    }

    .side-menu a {
        font-size: 12px;
    }
}