.sidnav {
    width: 100%;
    background-color: #ffffff;
    color: burlywood;
    background-color: transparent !important;
    position: relative;
    left: 0;
    
}

.menu-item {
    color: white !important;
    border-radius: 5px;
    padding:8px;
    width:150px !important;
}


.side-link{
    padding:8px;
}

.side-link-active {
    background-color: #120C54;
    /* padding: 5px; */
    color: white !important;
    cursor: pointer;
    border-radius: 5px;

}

/* .side-menu {
    list-style: none;
    padding: 0px;
    background-color: transparent;
    color: #333;
} */

/* .side-tab {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    text-decoration: none;
    color: white;
    padding-left: 10px;
} */

.menu-item hr {
    margin-bottom: 0px;
}

/* .side-link {
    text-decoration: none;
    color: initial;
    font-size: 16px;
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 5px;
} */


.side-link-logout {
    border: 0px;
    background-color: inherit;
    display: inline-block;
    width: 100%;
    color: inherit;
    text-align: start;
}