.align-evenly{
    /* box-shadow: 0 0 3px 1px #333; */
    padding: 20px;
    display: flex;
    justify-content: space-around;
}

.add-q-btns{
    display: flex;
    height: 50px;
    width: 70%;
    /* border:1px solid */
}